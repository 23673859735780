
html,body {
  box-sizing: border-box;
}
*, *:before, *:after { box-sizing: inherit; }

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
    display: none;
}

.MuiDrawer-paper {
    width: 100% !important;
    height: 100% !important;
}

.MuiBadge-badge {
    border: 2px solid #FFFFFF !important;
    background-color: #FF274B !important;
    color: #FFFFFF !important;
    padding: 0px 4px !important;
}

.MuiBadge-dot {
    height: 15px !important;
    width: 15px !important;
    border-radius: 100% !important;
}

body {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Manrope' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Added 09042020 by @alialfredji */
    -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
    -webkit-text-size-adjust: none; /* prevent webkit from resizing text to fit */
    user-select: none;
    -webkit-user-select: none; /* prevent copy paste, to allow, change 'none' to 'text' */
    /**/

    /* iOS only for safe area on iphone X and above */
    /* padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top); */
    /* */
}

#root {
    width: 100vw;
    height: 100vh;
    background-color: #FFFFFF;
    font-family: 'Manrope' !important;
}

textarea, input {
    font-family: 'Manrope' !important;
}
